import { getToken, returnError } from './helperFunc';
import {
	transReportAction,
	summaryReportAction,
	epayReportAction,
	terminalReportAction,
	manualPayReportAction,
	getPaymentTypesAction,
	getTerminalTypesAction,
	getDNGPaymentTypesAction,
	getCostingPaymentTypesAction,
	getCostingItemTypesAction,
	billReportAction,
	errorcodeReportAction,
	machineSalesTransReportAction,
	hourlySalesTransReportAction,
	salesByMachineTypeAction,
	systemErrorReportAction,
	systemErrorReportUpdateAction,
	dngReportAction,
	summaryReportOverallAction,
	vendingReportAction,
	costingReportAction,
	voidVendingAction,
	voidTransactionAction,
	saveColumnVisibilityAction,
	fetchColumnVisibilityAction,
	getOverallEpaymentTypesAction,
	overallEpaymentReportAction,
	saveFavoriteSearchAction,
	fetchFavoriteSearchAction,
	pnlReportAction,
	editFavoriteSearchAction,
	deleteFavoriteSearchAction
} from './requestEndpoints';
import { LOGOUT_SUCCESS } from './userReducer';

const SET_REPORT_LOADING = 'SET_REPORT_LOADING';
const CLEAR_REPORT_ERROR = 'CLEAR_REPORT_ERROR';

const TRANSACTION_REPORT_SUCCESS = 'TRANSACTION_REPORT_SUCCESS';
const TRANSACTION_REPORT_FAIL = 'TRANSACTION_REPORT_FAIL';

const MACHINE_SALES_TRANSACTION_REPORT_SUCCESS = 'MACHINE_SALES_TRANSACTION_REPORT_SUCCESS';
const MACHINE_SALES_TRANSACTION_REPORT_FAIL = 'MACHINE_SALES_TRANSACTION_REPORT_FAIL';

const HOURLY_SALES_TRANSACTION_REPORT_SUCCESS = 'HOURLY_SALES_TRANSACTION_REPORT_SUCCESS';
const HOURLY_SALES_TRANSACTION_REPORT_FAIL = 'HOURLY_SALES_TRANSACTION_REPORT_FAIL';

const SALES_SUMMARY_SUCCESS = 'SALES_SUMMARY_SUCCESS';
const SALES_SUMMARY_FAIL = 'SALES_SUMMARY_FAIL';

const EPAY_RECORD_SUCCESS = 'EPAY_RECORD_SUCCESS';
const EPAY_RECORD_FAIL = 'EPAY_RECORD_FAIL';

const TERMINAL_RECORD_SUCCESS = 'TERMINAL_RECORD_SUCCESS';
const TERMINAL_RECORD_FAIL = 'TERMINAL_RECORD_FAIL';

const OVERALL_EPAYMENT_RECORD_SUCCESS = 'OVERALL_EPAYMENT_RECORD_SUCCESS';
const OVERALL_EPAYMENT_RECORD_FAIL = 'OVERALL_EPAYMENT_RECORD_FAIL';

const DNG_RECORD_SUCCESS = 'DNG_RECORD_SUCCESS';
const DNG_RECORD_FAIL = 'DNG_RECORD_FAIL';

const VENDING_RECORD_SUCCESS = 'VENDING_RECORD_SUCCESS';
const VENDING_RECORD_FAIL = 'VENDING_RECORD_FAIL';

const COSTING_RECORD_SUCCESS = 'COSTING_RECORD_SUCCESS';
const COSTING_RECORD_FAIL = 'COSTING_RECORD_FAIL';

const BILL_RECORD_SUCCESS = 'BILL_RECORD_SUCCESS';
const BILL_RECORD_FAIL = 'BILL_RECORD_FAIL';

const MANUALPAY_RECORD_SUCCESS = 'MANUALPAY_RECORD_SUCCESS';
const MANUALPAY_RECORD_FAIL = 'MANUALPAY_RECORD_FAIL';

const ERROR_RECORD_SUCCESS = 'ERROR_RECORD_SUCCESS';
const ERROR_RECORD_FAIL = 'ERROR_RECORD_FAIL';

const SALES_BY_MACHINE_TYPE_SUCCESS = 'SALES_BY_MACHINE_TYPE_SUCCESS';
const SALES_BY_MACHINE_TYPE_FAIL = 'SALES_BY_MACHINE_TYPE_FAIL';

const SYSTEM_ERROR_REPORT_SUCCESS = 'SYSTEM_ERROR_REPORT_SUCCESS';
const SYSTEM_ERROR_REPORT_FAIL = 'SYSTEM_ERROR_REPORT_FAIL';

const SYSTEM_ERROR_REPORT_UPDATE_SUCCESS = 'SYSTEM_ERROR_REPORT_UPDATE_SUCCESS';
const SYSTEM_ERROR_REPORT_UPDATE_FAIL = 'SYSTEM_ERROR_REPORT_UPDATE_FAIL';

const SALES_SUMMARY_SUCCESS_DETAIL = 'SALES_SUMMARY_SUCCESS_DETAIL';
const SALES_SUMMARY_FAIL_DETAIL = 'SALES_SUMMARY_FAIL_DETAIL';

const VOID_TRANSACTION_SUCCESS = 'VOID_TRANSACTION_SUCCESS';
const VOID_TRANSACTION_FAIL = 'VOID_TRANSACTION_FAIL';

const VOID_VENDING_SUCCESS = 'VOID_VENDING_SUCCESS';
const VOID_VENDING_FAIL = 'VOID_VENDING_FAIL';

const SAVE_COLUMN_VISIBILITY_SUCCESS = 'SAVE_COLUMN_VISIBILITY_SUCCESS';
const SAVE_COLUMN_VISIBILITY_FAIL = 'SAVE_COLUMN_VISIBILITY_FAIL';

const FETCH_COLUMN_VISIBILITY_SUCCESS = 'FETCH_COLUMN_VISIBILITY_SUCCESS';
const FETCH_COLUMN_VISIBILITY_FAIL = 'FETCH_COLUMN_VISIBILITY_FAIL';

const SAVE_FAVORITE_SEARCH_SUCCESS = 'SAVE_FAVORITE_SEARCH_SUCCESS';
const SAVE_FAVORITE_SEARCH_FAIL = 'SAVE_FAVORITE_SEARCH_FAIL';

const FETCH_FAVORITE_SEARCH_SUCCESS = 'FETCH_FAVORITE_SEARCH_SUCCESS';
const FETCH_FAVORITE_SEARCH_FAIL = 'FETCH_FAVORITE_SEARCH_FAIL';

const PNL_RECORD_SUCCESS = 'PNL_RECORD_SUCCESS';
const PNL_RECORD_FAIL = 'PNL_RECORD_FAIL';

const EDIT_FAVORITE_SEARCH_SUCCESS = 'EDIT_FAVORITE_SEARCH_SUCCESS';
const EDIT_FAVORITE_SEARCH_FAIL = 'EDIT_FAVORITE_SEARCH_FAIL';

const DELETE_FAVORITE_SEARCH_SUCCESS = 'DELETE_FAVORITE_SEARCH_SUCCESS';
const DELETE_FAVORITE_SEARCH_FAIL = 'DELETE_FAVORITE_SEARCH_FAIL';

// FOR BROWSABLE REPORTS

// /api/report/sales/v1/epayment
// /api/report/sales/v1/manualpay

export const transReport = (values) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_REPORT_ERROR });
		dispatch({ type: SET_REPORT_LOADING });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await transReportAction(values, token);

		dispatch({ type: TRANSACTION_REPORT_SUCCESS, payload: data });
		// console.log(data);
	} catch (error) {
		dispatch({ type: TRANSACTION_REPORT_FAIL, payload: returnError(error) });
	}
};

export const voidTransaction = (cycleIds, isVoid) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_REPORT_ERROR });
		dispatch({ type: SET_REPORT_LOADING });

		const { user } = getState();
		const token = getToken(user);

		const { data } = await voidTransactionAction(cycleIds, isVoid, token);

		dispatch({ type: VOID_TRANSACTION_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: VOID_TRANSACTION_FAIL, payload: returnError(error) });
	}
};

export const voidVending = (transactionId, isVoid) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_REPORT_ERROR });
		dispatch({ type: SET_REPORT_LOADING });

		const { user } = getState();
		const token = getToken(user);

		const { data } = await voidVendingAction(transactionId, isVoid, token);

		dispatch({ type: VOID_VENDING_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: VOID_VENDING_FAIL, payload: returnError(error) });
	}
}
		
export const saveColumnVisibility = (hiddenColumns) => async (dispatch, getState) => {
	try {
		const { user } = getState();
		const token = getToken(user);

		const { data } = await saveColumnVisibilityAction(hiddenColumns, token);
		// console.log(data)

		dispatch({ type: SAVE_COLUMN_VISIBILITY_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: SAVE_COLUMN_VISIBILITY_FAIL, payload: returnError(error) });
	}
};

export const fetchColumnVisibility = () => async (dispatch, getState) => {
	try {
		const { user } = getState();
		const token = getToken(user);

		const { data } = await fetchColumnVisibilityAction(token);
		// console.log(data)

		dispatch({ type: FETCH_COLUMN_VISIBILITY_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: FETCH_COLUMN_VISIBILITY_FAIL, payload: returnError(error) });
	}
};

export const saveFavoriteSearch = (favoriteSearch) => async (dispatch, getState) => {
	try {
		const { user } = getState();
		const token = getToken(user);

		const { data } = await saveFavoriteSearchAction(favoriteSearch, token);
		dispatch({ type: SAVE_FAVORITE_SEARCH_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: SAVE_FAVORITE_SEARCH_FAIL, payload: returnError(error) });
	}
};

export const fetchFavoriteSearch = () => async (dispatch, getState) => {
	try {
		const { user } = getState();
		const token = getToken(user);

		const { data } = await fetchFavoriteSearchAction(token);
		dispatch({ type: FETCH_FAVORITE_SEARCH_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: FETCH_FAVORITE_SEARCH_FAIL, payload: returnError(error) });
	}
};

export const editFavoriteSearch = (favoriteSearch) => async (dispatch, getState) => {
	try {
		const { user } = getState();
		const token = getToken(user);

		const { data } = await editFavoriteSearchAction(favoriteSearch, token);
		dispatch({ type: EDIT_FAVORITE_SEARCH_SUCCESS, payload: data });
		dispatch(fetchFavoriteSearch()); 
	} catch (error) {
		dispatch({ type: EDIT_FAVORITE_SEARCH_FAIL, payload: returnError(error) });
	}
};

export const deleteFavoriteSearch = (favoriteSearchId) => async (dispatch, getState) => {
	try {
		const { user } = getState();
		const token = getToken(user);

		await deleteFavoriteSearchAction(favoriteSearchId, token);
		dispatch({ type: DELETE_FAVORITE_SEARCH_SUCCESS, payload: { favoriteSearchId } });
		dispatch(fetchFavoriteSearch()); 
	} catch (error) {
		dispatch({ type: DELETE_FAVORITE_SEARCH_FAIL, payload: returnError(error) });
	}
};

export const summaryReport = (values) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_REPORT_ERROR });
		dispatch({ type: SET_REPORT_LOADING });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await summaryReportAction(values, token);

		dispatch({ type: SALES_SUMMARY_SUCCESS, payload: data });
		// console.log(data);
	} catch (error) {
		dispatch({ type: SALES_SUMMARY_FAIL, payload: returnError(error) });
	}
};

export const summaryReportOverall = (values) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_REPORT_ERROR });
		dispatch({ type: SET_REPORT_LOADING });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await summaryReportOverallAction(values, token);

		dispatch({ type: SALES_SUMMARY_SUCCESS_DETAIL, payload: data });
		// console.log(data);
	} catch (error) {
		dispatch({ type: SALES_SUMMARY_FAIL_DETAIL, payload: returnError(error) });
	}
};

export const epayReport = (values) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_REPORT_ERROR });
		dispatch({ type: SET_REPORT_LOADING });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await epayReportAction(values, token);
		// console.log(data);
		dispatch({ type: EPAY_RECORD_SUCCESS, payload: data });
		// console.log(data);
	} catch (error) {
		dispatch({ type: EPAY_RECORD_FAIL, payload: returnError(error) });
	}
};

export const terminalReport = (values) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_REPORT_ERROR });
		dispatch({ type: SET_REPORT_LOADING });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await terminalReportAction(values, token);
		// console.log(data);
		dispatch({ type: TERMINAL_RECORD_SUCCESS, payload: data });
		// console.log(data);
	} catch (error) {
		dispatch({ type: TERMINAL_RECORD_FAIL, payload: returnError(error) });
	}
};

export const overallEpaymentReport = (values) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_REPORT_ERROR });
		dispatch({ type: SET_REPORT_LOADING });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await overallEpaymentReportAction(values, token);
		// console.log(data);
		dispatch({ type: OVERALL_EPAYMENT_RECORD_SUCCESS, payload: data });
		// console.log(data);
	} catch (error) {
		dispatch({ type: OVERALL_EPAYMENT_RECORD_FAIL, payload: returnError(error) });
	}
};

export const dngReport = (values) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_REPORT_ERROR });
		dispatch({ type: SET_REPORT_LOADING });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await dngReportAction(values, token);
		// console.log(data);
		dispatch({ type: DNG_RECORD_SUCCESS, payload: data });
		// console.log(data);
	} catch (error) {
		dispatch({ type: DNG_RECORD_FAIL, payload: returnError(error) });
	}
};

export const vendingReport = (values) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_REPORT_ERROR });
		dispatch({ type: SET_REPORT_LOADING });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await vendingReportAction(values, token);
		// console.log(data);
		dispatch({ type: VENDING_RECORD_SUCCESS, payload: data });
		// console.log(data);
	} catch (error) {
		dispatch({ type: VENDING_RECORD_FAIL, payload: returnError(error) });
	}
};


export const costingReport = (values) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_REPORT_ERROR });
		dispatch({ type: SET_REPORT_LOADING });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);
		const { data } = await costingReportAction(values, token);
		// console.log(data);
		dispatch({ type: COSTING_RECORD_SUCCESS, payload: data });
		// console.log(data);
	} catch (error) {
		dispatch({ type: COSTING_RECORD_FAIL, payload: returnError(error) });
	}
};

export const getBillReport = (values) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_REPORT_ERROR });
		dispatch({ type: SET_REPORT_LOADING });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await billReportAction(values, token);
		// console.log(data);
		dispatch({ type: BILL_RECORD_SUCCESS, payload: data });
		// console.log(data);
	} catch (error) {
		dispatch({ type: BILL_RECORD_FAIL, payload: returnError(error) });
	}
};

export const manualPayReport = (values) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_REPORT_ERROR });
		dispatch({ type: SET_REPORT_LOADING });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await manualPayReportAction(values, token);

		dispatch({ type: MANUALPAY_RECORD_SUCCESS, payload: data });
		// console.log(data);
	} catch (error) {
		dispatch({ type: MANUALPAY_RECORD_FAIL, payload: returnError(error) });
	}
};

export const errorcodeReport = (values) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_REPORT_ERROR });
		dispatch({ type: SET_REPORT_LOADING });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await errorcodeReportAction(values, token);

		dispatch({ type: ERROR_RECORD_SUCCESS, payload: data });
		// console.log(data);
	} catch (error) {
		dispatch({ type: ERROR_RECORD_FAIL, payload: returnError(error) });
	}
};

export const getPaymentTypes = () => async (dispatch, getState) => {
	try {
		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await getPaymentTypesAction(token);

		return data;
		// console.log(data);
	} catch (error) {
		return [];
	}
};

export const getTerminalTypes = () => async (dispatch, getState) => {
	try {
		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await getTerminalTypesAction(token);

		return data;
		// console.log(data);
	} catch (error) {
		return [];
	}
};

export const getOverallEpaymentTypes = () => async (dispatch, getState) => {
	try {
		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await getOverallEpaymentTypesAction(token);

		return data;
		// console.log(data);
	} catch (error) {
		return [];
	}
};

export const getDNGPaymentTypes = () => async (dispatch, getState) => {
	try {
		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await getDNGPaymentTypesAction(token);

		return data;
		// console.log(data);
	} catch (error) {
		return [];
	}
};

export const getCostingPaymentTypes = () => async (dispatch, getState) => {
	try {
		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await getCostingPaymentTypesAction(token);

		return data;
		// console.log(data);
	} catch (error) {
		return [];
	}
};

export const getCostingItemTypes = (operatorId) => async (dispatch, getState) => {
	try {
		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await getCostingItemTypesAction(operatorId, token);

		return data;
		// console.log(data);
	} catch (error) {
		return [];
	}
};

export const machineSalesTransReport = (values) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_REPORT_ERROR });
		dispatch({ type: SET_REPORT_LOADING });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await machineSalesTransReportAction(values, token);

		dispatch({
			type: MACHINE_SALES_TRANSACTION_REPORT_SUCCESS,
			payload: data,
		});
		// console.log(data);
	} catch (error) {
		dispatch({
			type: MACHINE_SALES_TRANSACTION_REPORT_FAIL,
			payload: returnError(error),
		});
	}
};

export const hourlySalesTransReport = (values) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_REPORT_ERROR });
		dispatch({ type: SET_REPORT_LOADING });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await hourlySalesTransReportAction(values, token);

		dispatch({
			type: HOURLY_SALES_TRANSACTION_REPORT_SUCCESS,
			payload: data,
		});
		// console.log(data);
	} catch (error) {
		dispatch({
			type: HOURLY_SALES_TRANSACTION_REPORT_FAIL,
			payload: returnError(error),
		});
	}
};

export const salesByMachineType = (values) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_REPORT_ERROR });
		dispatch({ type: SET_REPORT_LOADING });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await salesByMachineTypeAction(values, token);

		dispatch({ type: SALES_BY_MACHINE_TYPE_SUCCESS, payload: data });
		// console.log(data);
	} catch (error) {
		dispatch({ type: SALES_BY_MACHINE_TYPE_FAIL, payload: returnError(error) });
	}
};

export const systemErrorReportType = (values) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_REPORT_ERROR });
		dispatch({ type: SET_REPORT_LOADING });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await systemErrorReportAction(values, token);

		dispatch({ type: SYSTEM_ERROR_REPORT_SUCCESS, payload: data });
		// console.log(data);
	} catch (error) {
		dispatch({ type: SYSTEM_ERROR_REPORT_FAIL, payload: returnError(error) });
	}
};

export const systemErrorReportUpdate = (values) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_REPORT_ERROR });
		dispatch({ type: SET_REPORT_LOADING });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await systemErrorReportUpdateAction(values, token);

		dispatch({ type: SYSTEM_ERROR_REPORT_UPDATE_SUCCESS, payload: data });
		// console.log(data);
	} catch (error) {
		dispatch({ type: SYSTEM_ERROR_REPORT_UPDATE_FAIL, payload: returnError(error) });
	}
};

export const pnlReport = (values) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_REPORT_ERROR });
		dispatch({ type: SET_REPORT_LOADING });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await pnlReportAction(values, token);
		// console.log(data);
		dispatch({ type: PNL_RECORD_SUCCESS, payload: data });
		// console.log(data);
	} catch (error) {
		dispatch({ type: PNL_RECORD_FAIL, payload: returnError(error) });
	}
};

const initialState = {
	language: 'en',
	operator: null,
	error: null,
	loading: false,
	errorUpdated: null,
	message2: null,
	hiddenColumns: [],
	favoriteSearch: null,
};

export const reportReducer = function (state = initialState, action) {
	switch (action.type) {
		// this is for fetching loading time setter
		case SET_REPORT_LOADING:
			return {
				...state,
				loading: true,
			};

		case CLEAR_REPORT_ERROR:
			return {
				...state,
				error: null,
			};

		case TRANSACTION_REPORT_SUCCESS:
			// console.log('action.payload', action.payload);
			return {
				report: action.payload.data,
				loading: false,
				error: null,
			};

		case MACHINE_SALES_TRANSACTION_REPORT_SUCCESS:
			return {
				machineSalesTransaction: action.payload,
				loading: false,
				error: null,
			};

		case HOURLY_SALES_TRANSACTION_REPORT_SUCCESS:
			return {
				hourlySalesTransaction: action.payload,
				loading: false,
				error: null,
			};

		case SALES_BY_MACHINE_TYPE_SUCCESS:
			// console.log('action.payload', action.payload);
			return {
				salesByMachineTypeData: action.payload.data,
				summary: action.payload.summary,
				loading: false,
				error: null,
			};

		case SALES_SUMMARY_SUCCESS:
			return {
				salesSummaryData: action.payload.data,
				loading: false,
				error: null,
			};

		case SALES_SUMMARY_SUCCESS_DETAIL:
			return {
				salesSummaryData2: action.payload.data,
				loading: false,
				error: null,
			};

		case EPAY_RECORD_SUCCESS:
			return {
				epay: action.payload.data,
				loading: false,
				error: null,
			};

		case TERMINAL_RECORD_SUCCESS:
			return {
				terminal: action.payload.data,
				loading: false,
				error: null,
			};

		case OVERALL_EPAYMENT_RECORD_SUCCESS:
			return {
				overallEpayment: action.payload.data,
				loading: false,
				error: null,
			};

		case DNG_RECORD_SUCCESS:
			return {
				dng: action.payload.data,
				loading: false,
				error: null,
			};

		case VENDING_RECORD_SUCCESS:
			return {
				vending: action.payload.data,
				loading: false,
				error: null,
			};
			
		case COSTING_RECORD_SUCCESS:
			return {
				costing: action.payload.data,
				loading: false,
				error: null,
			};

		
		case VENDING_RECORD_SUCCESS:
			return {
				vending: action.payload.data,
				loading: false,
				error: null,
			};

		case BILL_RECORD_SUCCESS:
			return {
				billReport: action.payload.data,
				loading: false,
				error: null,
			};

		case MANUALPAY_RECORD_SUCCESS:
			return {
				manual: action.payload.data,
				loading: false,
				error: null,
			};

		case ERROR_RECORD_SUCCESS:
			return {
				errorReport: action.payload.data,
				loading: false,
				error: null,
			};

		case SYSTEM_ERROR_REPORT_SUCCESS:
			return {
				systemErrorReport: action.payload.data,
				loading: false,
				error: null,
			};
		case SYSTEM_ERROR_REPORT_UPDATE_SUCCESS:
			return {
				...state,
				errorUpdated: action.payload.data,
				loading: false,
				error: null,
			};

		case VOID_TRANSACTION_SUCCESS:
			return {
				...state,
				report: state.report.map((item) =>
					item.id === action.payload.data.id ? action.payload.data : item
				),
				loading: false,
				error: null,
			};
		
		case VOID_VENDING_SUCCESS:
			return {
				...state,
				message2: "Success",
				loading: false,
				error: null,
			};

		case VOID_TRANSACTION_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload,
			};

		case SAVE_COLUMN_VISIBILITY_SUCCESS:
			return {
				...state,
				hiddenColumns: action.payload.hiddenColumns,
				loading: false,
				error: null,
			};

		case FETCH_COLUMN_VISIBILITY_SUCCESS:
			return {
				...state,
				hiddenColumns: action.payload.hiddenColumns,
				loading: false,
				error: null,
			};

		case SAVE_FAVORITE_SEARCH_SUCCESS:
			return {
				...state,
				favoriteSearch: action.payload.favoriteSearch,
				loading: false,
				error: null,
			};
		case SAVE_FAVORITE_SEARCH_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload,
			};

		case FETCH_FAVORITE_SEARCH_SUCCESS:
			return {
				...state,
				favoriteSearch: action.payload.favoriteSearches,
				loading: false,
				error: null,
			};
		case FETCH_FAVORITE_SEARCH_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case PNL_RECORD_SUCCESS:
			return {
				pnl: action.payload.data,
				loading: false,
				error: null,
			};
		case EDIT_FAVORITE_SEARCH_SUCCESS:
			return {
				...state,
				favoriteSearch: action.payload.favoriteSearch,
				loading: false,
				error: null,
			};

		case DELETE_FAVORITE_SEARCH_SUCCESS:
			return {
				...state,
				favoriteSearch: state.favoriteSearch.filter(
					(fav) => fav.id !== action.payload.favoriteSearchId
				),
				loading: false,
				error: null,
			};

		case EDIT_FAVORITE_SEARCH_FAIL:
		case DELETE_FAVORITE_SEARCH_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload,
			};

		case LOGOUT_SUCCESS:
			return initialState;

		case TRANSACTION_REPORT_FAIL:
		case SALES_SUMMARY_FAIL:
		case SALES_SUMMARY_FAIL_DETAIL:
		case EPAY_RECORD_FAIL:
		case TERMINAL_RECORD_FAIL:
		case OVERALL_EPAYMENT_RECORD_FAIL:
		case MANUALPAY_RECORD_FAIL:
		case ERROR_RECORD_FAIL:
		case MACHINE_SALES_TRANSACTION_REPORT_FAIL:
		case HOURLY_SALES_TRANSACTION_REPORT_FAIL:
		case SALES_BY_MACHINE_TYPE_FAIL:
		case SYSTEM_ERROR_REPORT_FAIL:
		case SYSTEM_ERROR_REPORT_UPDATE_FAIL:
		case VOID_TRANSACTION_FAIL:
		case SAVE_COLUMN_VISIBILITY_FAIL:
		case FETCH_COLUMN_VISIBILITY_FAIL:
		case PNL_RECORD_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload,
			};

		default:
			return state;
	}
};
